var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Breadscrumbs',{staticClass:"header-bread-scrum",attrs:{"items":_vm.breadcrumbs}}),_c('v-layout',{attrs:{"column":""}},[_c('v-layout',{staticClass:"mb-2",attrs:{"justify-end":""}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onGotoCreatePostOffice}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus-circle-outline")]),_vm._v(" "+_vm._s(_vm.$t("create_post-office"))+" ")],1)],1),_c('v-layout',{staticClass:"mb-3",staticStyle:{"align-items":"end"},attrs:{"justify-space-between":""}},[_c('v-layout',{staticClass:"col-6 pa-0",attrs:{"column":""}},[_c('label',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(((_vm.$t('post_office_name')) + "/" + (_vm.$t('code_post_office'))))+" ")]),_c('v-text-field',{staticClass:"mr-8",attrs:{"outlined":"","dense":"","hide-details":"","clearable":"","prepend-inner-icon":"mdi-magnify","placeholder":((_vm.$t('place_holders.post_office_name')) + "/" + (_vm.$t('place_holders.code_post_office')))},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onClickSearch.apply(null, arguments)}},model:{value:(_vm.objSearch),callback:function ($$v) {_vm.objSearch=$$v},expression:"objSearch"}})],1),_c('v-btn',{staticClass:"col-2",attrs:{"color":"primary"},on:{"click":_vm.onClickSearch}},[_vm._v(" "+_vm._s(_vm.$t("search"))+" ")])],1),_c('v-data-table',{staticClass:"border-primary",attrs:{"hide-default-footer":"","no-data-text":_vm.$t('not_found'),"headers":_vm.headers,"header-props":{ sortIcon: 'mdi-menu-swap' },"items":_vm.items},on:{"click:row":_vm.viewDetail},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"max-width-4 text-truncate"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"max-width-4 text-truncate"},[_vm._v(" "+_vm._s(item.code)+" ")])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"max-width-4 text-truncate"},[_vm._v(" "+_vm._s(item.phone)+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"max-width-4 text-truncate"},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.privateFlag",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"max-width-4 text-truncate"},[_vm._v(" "+_vm._s(_vm.getTextStatusPo(item.privateFlag))+" ")])]}},{key:"footer",fn:function(){return [_c('custom-pagination',{attrs:{"page":_vm.page,"start-record":_vm.computedStartRecord,"end-record":_vm.computedEndRecord,"total-record":_vm.totalRecord,"total-page":_vm.totalPage,"on-change-page":_vm.onChangePage}})]},proxy:true}],null,true)}),_c('dialog-loading',{attrs:{"message":_vm.$t('processing')},model:{value:(_vm.showLoading),callback:function ($$v) {_vm.showLoading=$$v},expression:"showLoading"}}),_c('dialog-notification',{attrs:{"icon-src":_vm.getIcon,"message":_vm.message},model:{value:(_vm.showNoti),callback:function ($$v) {_vm.showNoti=$$v},expression:"showNoti"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }