<template>
  <div>
    <Breadscrumbs :items="breadcrumbs" class="header-bread-scrum" />
    <v-layout column>
      <v-layout justify-end class="mb-2">
        <v-btn color="primary" @click="onGotoCreatePostOffice">
          <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
          {{ $t("create_post-office") }}
        </v-btn>
      </v-layout>
      <v-layout justify-space-between class="mb-3" style="align-items: end">
        <v-layout column class="col-6 pa-0">
          <label class="text-subtitle-1">
            {{ `${$t('post_office_name')}/${$t('code_post_office')}` }}
          </label>
          <v-text-field
            v-model="objSearch"
            outlined
            dense
            class="mr-8"
            hide-details
            clearable
            prepend-inner-icon="mdi-magnify"
            :placeholder="`${$t('place_holders.post_office_name')}/${$t('place_holders.code_post_office')}`"
            @keydown.enter="onClickSearch"
          />
        </v-layout>

        <v-btn color="primary" class="col-2" @click="onClickSearch">
          {{ $t("search") }}
        </v-btn>
      </v-layout>
      <v-data-table
        class="border-primary"
        hide-default-footer
        :no-data-text="$t('not_found')"
        :headers="headers"
        :header-props="{ sortIcon: 'mdi-menu-swap' }"
        :items="items"
        @click:row="viewDetail"
      >
        <template v-slot:[`item.name`]="{ item }">
          <td class="max-width-4 text-truncate">
            {{ item.name }}
          </td>
        </template>
        <template v-slot:[`item.code`]="{ item }">
          <td class="max-width-4 text-truncate">
            {{ item.code }}
          </td>
        </template>
        <template v-slot:[`item.phone`]="{ item }">
          <td class="max-width-4 text-truncate">
            {{ item.phone }}
          </td>
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <td class="max-width-4 text-truncate">
            {{ item.email }}
          </td>
        </template>
        <template v-slot:[`item.privateFlag`]="{ item }">
          <td class="max-width-4 text-truncate">
            {{ getTextStatusPo(item.privateFlag) }}
          </td>
        </template>
        <template v-slot:footer>
          <custom-pagination
            :page="page"
            :start-record="computedStartRecord"
            :end-record="computedEndRecord"
            :total-record="totalRecord"
            :total-page="totalPage"
            :on-change-page="onChangePage"
          />
        </template>
      </v-data-table>
      <!-- Dialog loading -->
      <dialog-loading v-model="showLoading" :message="$t('processing')" />

      <!-- notifications -->
      <dialog-notification
        v-model="showNoti"
        :icon-src="getIcon"
        :message="message"
      />
    </v-layout>
  </div>
</template>

<script>
import Breadscrumbs from '@/components/base/Breadscrumbs'
import CustomPagination from "@/components/CustomPagination.vue";
import DialogLoading from "@/components/dialog/DialogLoading.vue";
import DialogNotification from "@/components/dialog/DialogNotification.vue";

import routePaths from "@/router/routePaths";
import constants from "@/constants";

import { DataUtils } from "@/helpers/dataUtils";
import { PostService } from "@/services/postService";
import { StorageService } from "@/services/storageService";
import { StringUtils } from "@/helpers/stringUtils";

export default {
  components: {
    Breadscrumbs,
    CustomPagination,
    DialogLoading,
    DialogNotification
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'list_post_offices',
          disabled: true,
          isActive: true,
        },
      ],
      headers: [
        {
          text: this.$t("post_office_name"),
          value: "name",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("code_post_office"),
          value: "code",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("sdt"),
          value: "phone",
          sortable: false,
          class: "text-subtitle-2",
        },
        { text: this.$t("email"), value: "email", class: "text-subtitle-2" },
        { text: this.$t("address"), value: "provinceName", class: "text-subtitle-2" },
        {
          text: this.$t("price_list_po_one"),
          value: "privateFlag",
          sortable: false,
          class: "text-subtitle-2",
        },
      ],
      items: [],
      totalPage: 0,
      page: 1,
      totalRecord: 0,
      objSearch: "",
      showNoti: false,
      typeNoti: constants.typeAlert.warning,
      message: "",
      showLoading: false,
      showConfirmTokenExpried: false,
      currentRole: JSON.parse(localStorage.getItem("CURRENT_USER")).roles,
      currentPoId: JSON.parse(localStorage.getItem("CURRENT_USER")).poId,
    }
  },
  computed: {
    computedStartRecord() {
      if (this.items !== null && this.items.length > 0) {
        return (this.page - 1) * constants.numberEmployeesPerPage + 1;
      } else {
        return 0;
      }
    },
    computedEndRecord() {
      if (this.items !== null && this.items.length > 0) {
        return (this.page - 1) * constants.numberEmployeesPerPage + this.items.length;
      } else {
        return (this.page - 1) * constants.numberEmployeesPerPage;
      }
    },
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti);
    },
  },
  created() {
    if (window.location.pathname && !this.currentRole.includes("ROLE_ADMIN") || this.currentPoId !== 2) {
      this.toggleDialogNoti({
        state: true,
        msg: this.$t("no-mission-msg"),
      });
      setTimeout(() => {
        this.$router.go(-1);
      }, constants.timeOutRole);
    }
  },
  beforeMount() {
    this.initData();
  },
  methods: {
    async initData() {
      this.search();
    },
    onClickSearch() {
      this.page = 1;
      this.search();
    },
    onChangePage(page) {
      this.page = page;
      this.search();
    },
    async search() {
      this.showLoading = true;
      const pars = this.bindRequestPars();
      const { status, data } = await PostService.getPostOffice(pars);
      this.showLoading = false;
      if (status === constants.statusCode.ok && data) {
        this.totalRecord = data.totalRecord;
        if (data.totalRecord > 0) {
          this.items = data.data;
        } else {
          this.items = [];
        }
        this.totalPage = DataUtils.calculateEmployeePage(this.totalRecord);
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message });
      }
    },
    bindRequestPars() {
      const pars = {
        keyword: "",
        status: 1,
        limit: constants.numberEmployeesPerPage,
        currentPage: this.page,
      }
      if (this.objSearch) {
        pars.keyword = this.objSearch.trim()
      }
      return pars
    },
    getTextStatusPo(item) {
      if (item === constants.statusPo.private) {
        return this.$t("private")
      } else if (item === constants.statusPo.public) {
        return this.$t("public")
      }
    },
    toggleStatusSearch() {
      this.$nextTick(() => {
        if (this.likesAllStatus) {
          this.regencySelected = [];
        } else {
          this.regencySelected = this.bindStatusCodes(this.regencyItems);
        }
      });
    },
    toggleDialogNoti(
      pars = { state: false, type: constants.typeAlert.warning, msg: "" }
    ) {
      this.showNoti = pars.state;
      this.typeNoti = pars.type;
      this.message = pars.msg;
    },
    onGotoCreatePostOffice() {
      this.$router.push(routePaths.CREATE_POST_OFFICE);
    },
    viewDetail(item) {
      this.$router.push({
        path: routePaths.DETAIL_POST_OFFICE,
        query: { id: item.id },
      });
    },
  },
}
</script>

<style src="@/styles/createMultiOrder.scss" lang="scss">
</style>
